import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Dr. Kaloti in The Media – Press Release | The Smile Stylist" 
    description="Get up to the date news, press and interviews on The Smile Stylist, Toronto’s award winning and top cosmetic dentist." 
    keywords="Top cosmetic dentist" />
    <main className="site-content prindex" role="main">
        <section id="main-top-img" className="news-press hidden-xs">
        <div className="container">
            <div className="row">
            <div className="col-sm-12 sec-title wow animated fadeInDown">
                <div>
                <h1>Dr. Kaloti in the Media</h1>
                <h2>We won't deny it: our Smile Stylist is a popular guy.</h2>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section id="main-top-img" className="news-press hidden-sm hidden-md hidden-lg">
        <div className="container">
            <div className="row">
            <p>&nbsp;</p>
            </div>
        </div>
        </section>
        <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
        <div className="container">
            <div className="row">
            <div className="col-sm-12 sec-title wow animated fadeInDown">
                <h2>Dr. Kaloti in the Media</h2>
                <h2>We won't deny it: our Smile Stylist is a popular guy.</h2>
            </div>
            </div>
        </div>
        </section>
        <section id="main-white">
        <div className="container">
            <div className="row">
            <div className="col-md-8 col-sm-9 wow animated fadeInRight" id="blog">
                <p className="lead-black">Check back regularly for all the latest interviews, news stories, and updates on his next moves.</p>
                <hr />
                <div className="blog-art">
                <div className="img-blog img-thumbnail">
                    <img alt="Dr. Kaloti in the Media" src={ require("../../images/desktop/kaloti6.png" )} />
                </div>
                <div className="text-blog">
                    <h4><a href="/press-release/more-than-a-smile">MORE THAN A SMILE – DR. HISHAM KALOTI</a></h4>
                    <p>
                    Bestselling author, international speaker and philanthropist Dr. Hisham Kaloti is a leader in cosmetic dentistry. In fact, his passion for creating one-of-a-kind smiles has been featured on national TV. But even more significantly, he knows the importance of balance in life. For him, it’s all about a great smile, hanging out with his family and giving back.<br />
                    <span><a href="/press-release/more-than-a-smile">Continue reading...<i className="fa fa-chevron-right"></i></a></span>
                    </p>
                </div>
                </div>
                <div className="blog-art">
                <div className="img-blog img-thumbnail">
                    <img alt="Tennis Canada" src={ require("../../images/desktop/_pressrelease/pics/kaloti5.png" )} />
                </div>
                <div className="text-blog">
                    <h4><a href="/press-release/tennis-canada-rogers-cup">Toronto’s Smile Stylist Partners with Tennis Canada and Rogers Cup Presented by National Bank</a></h4>
                    <p>
                    He’s Giving Canadian Tennis Fans Something to Smile About<br />
                    <span><a href="/press-release/tennis-canada-rogers-cup">Continue reading...<i className="fa fa-chevron-right"></i></a></span>
                    </p>
                </div>
                </div>
                <div className="blog-art">
                <div className="img-blog img-thumbnail">
                    <img alt="Oakville Club Magazine" src={ require("../../images/desktop/_pressrelease/pics/oakville-club.jpg" )} />
                </div>
                <div className="text-blog">
                    <h4><a href="/press-release/oakville-club-magazine">Check Out Our Smile Stylist Featured In The Oakville Club Magazine!</a></h4>
                    <p>
                    The Oakville Club, a private members-only waterfront club located on Water Street recently featured our Smile Stylist in their latest magazine issue! Here’s what they had to say.<br />
                    <span><a href="/press-release/oakville-club-magazine">Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                    </p>
                </div>
                </div>
                <div className="blog-art">
                <div className="img-blog img-thumbnail">
                    <img alt="Dr. Kaloti The Smile Stylist" src={ require("../../images/desktop/_pressrelease/pics/canadian-miles.jpg" )} />
                </div>
                <div className="text-blog">
                    <h4><a href="/press-release/get-ready-to-meet-your-smile-stylist">Canada: Get Ready to Meet Your Smile Stylist</a></h4>
                    <p>
                    Dr. Hisham Kaloti is bringing the Smile Stylist to Canada, and is ready to begin transforming Canadian smiles. Originating from the Jacksonville, FL Smile Stylist enterprise; Dr. Kaloti has been practicing cosmetic dentistry for over 18 years – earning his Canadian Smile Stylist designation deservedly.<br />
                    <span><a href="/press-release/get-ready-to-meet-your-smile-stylist">Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                    </p>
                </div>
                </div>
                <div className="blog-art">
                <div className="img-blog img-thumbnail">
                    <img alt="SKYN Magazine" src={ require("../../images/desktop/_pressrelease/pics/skyn.jpg" )} />
                </div>
                <div className="text-blog">
                    <h4><a href="https://www.skynmagazine.com/dr-kaloti" target="_blank">SKYN magazin took a moment to chat with Dr. Kaloti</a></h4>
                    <p>
                    Toronto-based online lifestyle & culture publication Skyn Magazine gets real with Dr. Kaloti about life, his passion (crafting beautiful smiles), and about what truly makes him smile in an inspiring interview.<br />
                    <span><a href="https://www.skynmagazine.com/dr-kaloti" target="_blank">Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                    </p>
                </div>
                </div>
                <div className="blog-art">
                <div className="img-blog img-thumbnail">
                    <img alt="Dream Wedding Contest" src={ require("../../images/desktop/_pressrelease/pics/win-a-wedding.jpg" )} />
                </div>
                <div className="text-blog">
                    <h4><a href="/press-release/dream-wedding-contest">We’re Excited to be a part of Z103.5’s Dream Wedding Contest!</a></h4>
                    <p>
                    Z103.5’s Dream Wedding contest gives engaged Ontario couples a shot at winning their perfect wedding – a $50,000 value – which will include a max. $10,000 value smile makeover by the Smile Stylist himself!<br />
                    <span><a href="/press-release/dream-wedding-contest">Continue reading... <i className="fa fa-chevron-right"></i></a></span>
                    </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 col-sm-3 wow animated zoomIn" data-wow-delay="0.6s">

                <img alt="Press Release" src={ require("../../images/desktop/services/press.png" )} className="img-responsive" />
            </div>
            </div>
        </div>
        </section>
    </main>
  </Layout>
)

export default AboutKalotiPage